<template>
  <v-container class="fill-height page-login messaging pa-0" fluid>
    <v-row>
      <v-col>
        <v-card class="pa-3 page-login__card" tile>
          <v-card-title>
            <img src="/static/m.png" alt="มูลนิธิแม่ฟ้าหลวง" width="55" />
            <h1 class="primary--text display-1">
              แบบประเมินผลการศึกษาดูงาน<br />
              มูลนิธิแม่ฟ้าหลวง
            </h1>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              class="my-10"
              lazy-validation
              v-model="formValid"
            >
              <h2>บันทึกข้อมูลเรียบร้อยแล้ว ขอบคุณสำหรับการทำแบบประเมิน</h2>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { uuid } from 'vue-uuid'
export default {
  name: 'Complete',
  components: {},
  data() {
    return {
      token: ''
    }
  },
  created() {},
  computed: {},
  methods: {}
}
</script>
